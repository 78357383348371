@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "hindSiliguri";
  src: url("./assets/fonts/HindSiliguri-Medium.ttf");
}

@layer base {
  html {
    font-family: 'hindSiliguri';
  }
}

.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  @apply input input-bordered w-full;
}

.react-datepicker-wrapper .react-datepicker__input-container button {
  @apply btn btn-circle h-full bg-transparent hover:bg-transparent;
}

.react-datepicker__close-icon::after {
  @apply bg-gray-400;
}
