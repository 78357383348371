.react-daterange-picker__calendar--open {
  z-index: 1000;
}

.react-calendar__tile--active {
  color: black;
}

.dateInput{
  height: 2.4rem!important;
}
